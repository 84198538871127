import { keepNumber } from '@assets/js/regExUtil';
import { getDateNoTime } from '@assets/js/dateUtils';

let isRequType3 = false;
let stff_id = '';
export const changeisRequType3 = type => (isRequType3 = type);
export const changeStffId = id => (stff_id = id);

export const requSmplTableProperties = [
  {
    prop: 'sample_status',
    itemType: 'input',
    label: '样品状态',
    labelWidth: '100',
    sortable: false
  },
  {
    prop: 'generate_status',
    itemType: 'input',
    label: '工厂打样单情况',
    sortable: false
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    sortable: false,
    visible: true,
    labelWidth: '140'
  },
  {
    prop: 'group_type',
    itemType: 'input',
    label: '使用工艺信息',
    labelWidth: '180',
    sortable: false,
    subItem: {
      required: true,
      type: 'newSelect',
      options: {
        0: { label: '组合(产品设计组的稿件)', value: 0 },
        1: { label: '单品(产品设计单的稿件)', value: 1 }
      },
      clearable: true,
      maxLength: 20
    }
  },
  {
    label: '产品图片',
    prop: 'imge_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 220,
    subItem: {
      required: false,
      disabled: false,
      type: 'uploadImage',
      imageField: 'imge_id',
      thumbnailField: 'picture_url',
      image: row => row.picture_url,
      thumbnail: row => row.picture_url,
      specialShow: true
    }
  },
  {
    prop: 'fequ_no',
    itemType: 'input',
    label: '工厂打样单号',
    labelWidth: '150',
    sortable: false
  },
  {
    prop: 'desi_no',
    itemType: 'input',
    label: '产品设计单号',
    labelWidth: '150',
    sortable: false
  },
  { prop: 'dequ_no', itemType: 'input', label: '委托设计单号', labelWidth: '150', sortable: false },
  { prop: 'prod_no', itemType: 'input', label: '我司货号', labelWidth: '150', sortable: false },
  {
    prop: 'old_prod_cust_no',
    itemType: 'input',
    label: '老ERP货号',
    labelWidth: '150',
    sortable: false
  },
  {
    prop: 'prod_suffix',
    itemType: 'input',
    label: '更改号',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'input',
      clearable: true,
      maxLength: 2,
      input: (val, row) => (row.prod_suffix = keepNumber(val))
    }
  },
  {
    prop: 'prod_cust_no',
    itemType: 'input',
    label: '客户货号',
    labelWidth: '150',
    sortable: false,
    subItem: {
      required: false,
      type: 'input',
      clearable: true,
      maxLength: 20
    }
  },
  { prop: 'fequ_supp_abbr', itemType: 'input', label: '分配打样间', labelWidth: '150', sortable: false, visible: true },
  {
    prop: 'requ_smpl_made',
    itemType: 'input',
    label: '样品材质',
    labelWidth: '180',
    sortable: false,
    subItem: {
      disabled: false,
      required: true,
      type: 'select',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'prod_name',
    label: '产品名称',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: true,
      type: 'input',
      clearable: true
    }
  },
  {
    prop: 'prod_ename',
    itemType: 'input',
    label: '英文品名',
    labelWidth: '150',
    sortable: false,
    visible: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'input',
      clearable: true
    }
  },
  {
    prop: 'series_name',
    itemType: 'input',
    label: '系列名',
    labelWidth: '150',
    sortable: false,
    visible: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'input',
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'cust_num',
    itemType: 'input',
    label: '客户数量',
    labelWidth: '150',
    sortable: false,
    visible: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'input',
      input: (val, row) => (row.cust_num = keepNumber(val)),
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_sample_ask',
    itemType: 'input',
    label: '样品要求',
    labelWidth: '180',
    sortable: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'popoverInput',
      maxLength: '100',
      clearable: true
    }
  },
  {
    prop: 'prod_spec',
    itemType: 'input',
    label: '产品规格',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'input',
      clearable: true
    }
  },
  {
    prop: 'difficulty_level',
    itemType: 'input',
    label: '难易等级',
    labelWidth: '150',
    sortable: false,
    visible: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'select',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_smpl_num',
    itemType: 'input',
    label: '样品数量',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: true,
      type: 'input',
      maxLength: '7',
      input: (val, row) => (row.requ_smpl_num = keepNumber(val)),
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_smpl_type',
    itemType: 'input',
    label: '样品类型',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: true,
      type: 'newSelect',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_smpl_quot',
    itemType: 'input',
    label: '委托类型',
    labelWidth: '150',
    sortable: false,
    subItem: {
      required: true,
      type: 'newSelect',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_smpl_pedate',
    itemType: 'input',
    label: '要求交样日期',
    labelWidth: '180',
    sortable: false,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      disabled: false,
      required: true,
      type: 'date',
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'prod_content',
    itemType: 'input',
    label: '包装要求',
    labelWidth: '180',
    sortable: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'popoverInput',
      maxLength: '50',
      clearable: true
    }
  },
  {
    prop: 'prod_type',
    itemType: 'input',
    label: '样品类别',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: true,
      type: 'select',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'prod_unit',
    itemType: 'input',
    label: '样品单位',
    labelWidth: '150',
    sortable: false,
    subItem: {
      disabled: false,
      required: true,
      type: 'select',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'mould_no',
    itemType: 'input',
    label: '模具编号',
    labelWidth: '150',
    sortable: false,
    visible: false,
    subItem: {
      disabled: true,
      required: false,
      type: 'input',
      clearable: true
    }
  },
  {
    prop: 'requ_smpl_taker',
    itemType: 'input',
    label: '打样接收人',
    labelWidth: '200',
    sortable: false,
    subItem: {
      disabled: false,
      required: false,
      type: 'newSelect',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_smpl_qudate',
    itemType: 'input',
    label: '要求核价日期',
    labelWidth: '180',
    sortable: false,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      disabled: row => {
        //为非毛绒时 子表如果打样接收人和报价接收人不是同一个人，登录人为打样接收人时不能动报价和核价列（要求报价日期，核价接收人，报价接收人）
        if (isRequType3) {
          if (row.requ_smpl_taker !== row.requ_smpl_quoter && row.requ_smpl_taker === stff_id) {
            return true;
          }
        } else {
          return false;
        }
      },
      required: false,
      type: 'date',
      clearable: true
    }
  },
  {
    prop: 'requ_smpl_quoter',
    itemType: 'input',
    label: '报价接收人',
    labelWidth: '200',
    sortable: false,
    subItem: {
      required: false,
      type: 'newSelect',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  {
    prop: 'requ_smpl_pricer',
    itemType: 'input',
    label: '核价接收人',
    labelWidth: '200',
    sortable: false,
    subItem: {
      required: false,
      type: 'newSelect',
      options: {},
      clearable: true,
      copyAndPaste: true
    }
  },
  { prop: 'business_back_num', itemType: 'input', label: '退样次数', labelWidth: '150', sortable: false }
];
